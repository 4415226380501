@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

.navbar {
  .navbar-brand {
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    font-weight: 700;
  }
}

.nav-link {
  font-family: "Lato", sans-serif;
}

/*navbar second row*/
.navbar-sr {
  .nav-link {
    font-size: 1.1rem;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}

/*navbar first row*/
.navbar-fr {
  .nav-link {
    font-size: 0.9rem;
  }
}

.transparent-navbar {
  background-color: transparent !important;
  .nav-link {
    color: white !important;
  }
}

.white-navbar {
  background-color: #fff !important;
  .nav-link {
    color: black !important;
  }
}

.bkg-image-container {
  position: relative;
  overflow: hidden;
  height: 100vh;

  img {
    position: absolute;
    min-width: 100vw;
    height: 100%;
    object-fit: cover;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    filter: brightness(90%);
  }
}

.slogan {
  max-width: 50vw;
  position: absolute;
  margin-top: 15vh;
  margin-left: 6vw;
  z-index: 1;

  h1 {
    color: #fff;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 8vh;
    margin-bottom: 2vh;
  }
}

.footer {
  border-top: 1px solid #000;
  .footer-info {
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    div {
      flex: 1 1;
    }
    a {
      text-decoration: none;
      color: #000;
    }
  }
  .disclaimer {
    color: grey;
    font-size: 8px;
    padding: 10px;
  }
}

.table {
  text-align: center;
}

path {
  &:hover {
    fill-opacity: 0.5 !important;
  }
}

.available-row {
  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
}

.mieszkanie-details {
  padding: 24px 0px;
  table {
    td {
      padding: 2px 6px;
    }
  }
}

.oferta {
  .mobile-accessibility {
    display: none;
    flex-direction: column;
    min-height: 22rem;
    justify-content: space-evenly;
    .mobile-slogan {
      text-align: center;
      margin-top: 3rem;
    }
    .mobile-subslogan {
      text-align: center;
      margin-bottom: 3rem;
    }
    .accessibility-buttons-container {
      display: flex;
      justify-content: space-around;
      margin-bottom: 3rem;
    }
  }
}

.birdview {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.budynek {
  .infobox {
    background-color: #fff;
    width: fit-content;
    padding: 12px;
    position: absolute;
    top: 6rem;
    right: 5vw;
    border-radius: 4px;
    max-width: 25vw;
    .buttons-container {
      display: flex;
      justify-content: space-between;
    }
  }
  .infobox-sm {
    display: none;
    padding: 3rem;
    .buttons-container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.poziom {
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .rzut-container {
      flex: 1 1;
      max-height: 75vh;
      height: 100vh;
    }
    .details-container {
      flex: 2 1;
      .details {
        flex-direction: column;
        padding: 1rem;
      }
    }
    .details-container-mobile {
      display: none;
      padding: 2rem;
    }
  }
}

.mieszkanie {
  display: flex;
  flex-direction: row;
  align-items: center;
  .rzut-container {
    flex: 1 1;
    justify-content: center;
    display: flex;
  }
  .info {
    flex: 1 1;
    flex-direction: column;
    padding: 1rem;
    .images-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.desktop-hide {
  display: none;
}

.index {
  .description-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .image-container {
      text-align: center;
    }

    .text-container {
      padding: 1.5rem;
      .title {
        margin-bottom: 2rem;
      }

      .content {
        margin-bottom: 2rem;
      }
    }
  }
}

.navbar-links {
  display: flex;
  div {
    padding: 0.5rem;
    a {
      text-decoration: none;
      font-size: 0.75rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .index {
    .slogan {
      h1 {
        font-size: 3rem;
      }
    }
    .description-container {
      .text-container {
        .title {
          margin-bottom: 2rem;
        }

        .content {
          margin-bottom: 2rem;
        }

        .button-container {
          margin: auto;
          width: fit-content;
        }
      }

      .image-container {
        img {
          width: 70%;
        }
      }
    }
  }
  .oferta {
    .bkg-image-container {
      height: fit-content;
      .birdview {
        position: static;
      }
      .slogan {
        h1 {
          font-size: 3rem;
        }
      }
    }
    .mobile-accessibility {
      display: flex;
    }
  }
  .budynek {
    .infobox {
      width: 12rem;
    }
  }
  .poziom {
    .wrapper {
      flex-direction: column;
      align-items: unset;
      .rzut-container {
        max-height: none;
        padding-bottom: 2rem;
      }
      .details {
        display: none;
      }
      .details-container-mobile {
        display: block;
      }
    }
  }
  .mieszkanie {
    min-height: 73vh;
  }
}

@media screen and (max-width: 768px) {
  .oferta {
    .bkg-image-container {
      .slogan {
        h1 {
          font-size: 2.5rem;
        }
      }
    }
  }

  .index {
    .description-container {
      flex-direction: column;
      text-align: center;

      .text-container {
        text-align: left;
      }

      .image-container {
        img {
          width: 70%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .index {
    .slogan {
      max-width: 80vw;
      h1 {
        font-size: 2rem;
      }
    }
    .description-container {
      .text-container {
        padding: 1.5rem 0rem;
      }
      .image-container {
        img {
          width: 50%;
        }
      }
    }
  }

  .oferta {
    .bkg-image-container {
      .slogan {
        h1 {
          font-size: 2rem;
        }
      }
    }
    .accessibility-buttons-container {
      flex-direction: column;
      align-items: center;
      button {
        width: 50%;
        margin: 1rem;
      }
    }
  }

  .budynek {
    .infobox {
      display: none;
    }
    .infobox-sm {
      display: block;
    }
  }

  .mobile-hide {
    display: none;
  }

  .table {
    font-size: 0.75rem;
  }

  .mieszkanie {
    flex-direction: column;
  }

  .desktop-hide {
    display: block;
  }

  .footer {
    .footer-info {
      flex-direction: column;
      > div {
        padding-bottom: 1rem;
      }
    }
  }

  .navbar-links {
    flex-direction: column;
    div {
      padding: 0rem;
    }
  }
}

@media screen and (max-width: 520px) {
  .oferta {
    .bkg-image-container {
      .slogan {
        top: 5rem;
        margin-top: 0px;
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .oferta {
    .bkg-image-container {
      .slogan {
        top: 4rem;
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }

  .hide-under-w-420 {
    display: none;
  }

  .logo {
    font-size: 1.5rem;
  }
}
